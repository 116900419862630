import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BottomSheetService {
  // Holds the height of the bottom sheet when it is open
  public onBottomSheetHeight = new Subject<number>();
  public onBottomSheetOpen = new BehaviorSubject<boolean>(false);

  public open(): void {
    this.onBottomSheetOpen.next(true);
  }

  public close(): void {
    this.onBottomSheetOpen.next(false);
    this.onBottomSheetHeight.next(0);
  }
}
