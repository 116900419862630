import { Injectable } from "@angular/core";
import { ICON_SIZES } from "@backend/graph/constants";
import { BrandInfoEntry } from "src/app/data_model/brand-info";
import type { T_BrandThemeOverride } from "./brand.service";
import { JWTService } from "./jwt.service";
import { LocationService } from "./location.service";
import { WindowService } from "./window.service";
import { SwUpdate } from "@angular/service-worker";
import { Subject, timer, take, Subscription } from "rxjs";
import { NotificationInstance, NotificationService, NotificationTypes } from "./notification.service";
import { environment } from "src/environments/environment";
import Bugsnag from "@bugsnag/js";
import { pipThemeColor } from "../utils/theme";

const { host, protocol } = window.location;

export interface I_ManifestShortcut {
  name: string;
  url: string;
  description: string;
  icons: Array<I_ManifestIcon>;
}

export interface I_ManifestScreenshot {
  src: string;
  type: string;
  sizes: string;
  form_factor: string;
  label: string;
}

export interface I_Manifest {
  name: string;
  short_name: string;
  description: string;
  categories: Array<string>;
  start_url: string;
  scope: string;
  background_color: string;
  theme_color: string;
  display?: string;
  orientation: string;
  icons: Array<I_ManifestIcon>;
  screenshots: Array<I_ManifestScreenshot>;
  shortcuts: Array<I_ManifestShortcut>;
}

const DEFAULT_FAVICON_PATH = `${protocol}//${host}/assets/`;
const DEFAULT_FAVICON_HTML = `<link rel="icon" type="image/x-icon" href="${DEFAULT_FAVICON_PATH}favicon.ico" />`;
const PWA_SCREENSHOTS_PATH = `${protocol}//${host}/assets/screenshots/`;
export const PWA_SHORTCUTS_PATH = `${protocol}//${host}/assets/shortcuts/`;

export interface I_ManifestIcon {
  src: string;
  type: string;
  sizes: string;
}

@Injectable({
  providedIn: "root",
})
export class PwaService {
  private _timerSub: Subscription;
  constructor(
    private _jwtService: JWTService,
    private _locationService: LocationService,
    private _windowService: WindowService,
    private _swUpdate: SwUpdate,
    private _notificationService: NotificationService
  ) {}

  public get isPWA(): boolean {
    if ("standalone" in this._windowService.navigator) {
      return this._windowService.navigator.standalone === true;
    }

    const mediaQuery = this._windowService.matchMedia("(display-mode: standalone)");

    return !!mediaQuery?.matches;
  }

  public setupPwa(brand?: BrandInfoEntry, themeOverride?: T_BrandThemeOverride): void {
    this._setupManifest(brand, themeOverride);
    this._setupFavIcons(brand, themeOverride);
  }

  private _setupManifest(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): void {
    const manifestEl = document.getElementById("web-manifest");
    const manifest = this._generateManifest(brand, themeOverride);
    const isPiP = this._locationService.isPairDomain || this._jwtService.isPip();

    if (manifestEl && isPiP) manifestEl.setAttribute("href", manifest);
  }

  public async unregisterServiceWorker(): Promise<void> {
    try {
      // Check for the angular service worker and unregister it
      if (window.navigator && navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          const isAngularServiceWorker = registration.active?.scriptURL.includes("ngsw-worker.js");
          if (isAngularServiceWorker) await registration.unregister();
        }
      }
    } catch (error) {
      Bugsnag.notify("Error unregistering service worker", error);
    }
  }

  private _setupFavIcons(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): void {
    const iconsEl = document.querySelectorAll("link[rel='icon']");
    // Remove all existing icons elements
    iconsEl.forEach((el) => el.remove());

    const iconHtml = !brand?.favicon_preview_url ? DEFAULT_FAVICON_HTML : this._generateIconsMarkup(brand, themeOverride);

    // Add the icons to the page
    document.querySelector("head")?.insertAdjacentHTML("beforeend", iconHtml);
  }

  private _generateManifest(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): string {
    const isLoggedIn = this._jwtService.isLoggedIn();
    const prefix = isLoggedIn ? "/my-dental" : "";
    const start_url = `${window.location.origin}${prefix}`;

    const manifest: I_Manifest = {
      name: "Dentally Portal",
      short_name: "Dentally Portal",
      description: "Your complete digital in practice assistant. Streamline check-ins, forms and treatment plans with ease.",
      start_url,
      categories: ["health", "medical", "productivity"],
      scope: start_url,
      background_color: "#FFFFFF",
      theme_color: pipThemeColor,
      display: "fullscreen",
      orientation: "any",
      icons: this._generateManifestIcons(brand, themeOverride),
      screenshots: this._generateScreenshots(),
      shortcuts: this._generatePatientShortcuts(start_url),
    };

    // Adding a display property makes the PWA installable and passes the audit (https://developer.chrome.com/docs/lighthouse/pwa/installable-manifest)
   // if (environment.PWA_INSTALLABLE === "true") manifest.display = "standalone";

    const content = encodeURIComponent(JSON.stringify(manifest));
    const url = "data:application/manifest+json," + content;

    return url;
  }

  public async checkUpdates(): Promise<void> {
    try {
      if (!this._jwtService.isPip() || !this._swUpdate.isEnabled) return;

      await this._swUpdate.checkForUpdate();

      this._swUpdate.versionUpdates.subscribe((v) => {
        if (v.type === "VERSION_READY") {
          Bugsnag.notify(new Error("New PWA version available"));

          let newTime = 5;
          const notificationInstance = new NotificationInstance({
            title: "New version available",
            body: `A new version of the portal is available. Page will refresh in ${newTime} seconds.`,
            type: NotificationTypes.INFO,
            actionText: "Refresh",
            actionFn: () => window.location.reload(),
            onTextUpdate: new Subject<string>(),
          });

          const timer$ = timer(0, 1000).pipe(take(6));

          this._timerSub = timer$.subscribe((time) => {
            newTime = 5 - time;
            notificationInstance.onTextUpdate?.next(`A new version of the portal is available. Page will refresh in ${newTime} seconds.`);
            if (newTime === 0) {
              window.location.reload();
              this._timerSub.unsubscribe();
            }
          });

          this._notificationService.open(notificationInstance);
        }
      });
    } catch (_error) {
      Bugsnag.notify("Error checking for PWA updates");
    }
  }

  private _generateScreenshots(): Array<I_ManifestScreenshot> {
    const screenshots = new Array<I_ManifestScreenshot>();
    const sizes: Record<string, string> = {
      narrow: "320x640",
      wide: "640x480",
    };

    const files: Record<string, string> = {
      start: "Patient check-in",
      medical: "Filling in a medical history form aheaad of an appointment",
      complete: "Ready for an appointment",
    };

    for (const size of Object.keys(sizes)) {
      for (const [key, value] of Object.entries(files)) {
        const src = `${PWA_SCREENSHOTS_PATH}${size}/${key}.png`;
        screenshots.push({
          src,
          type: "image/png",
          sizes: sizes[size],
          form_factor: size,
          label: value,
        });
      }
    }

    return screenshots;
  }

  private _generatePatientShortcuts(start_url: string): Array<I_ManifestShortcut> {
    return [
      {
        name: "Send NHS PR Form",
        url: `${start_url}/pip-login/action/NHS_PR`,
        description: "Send NHS PR form to the patient",
        icons: [
          {
            src: `${PWA_SHORTCUTS_PATH}action.png`,
            type: "image/png",
            sizes: "96x96",
          },
        ],
      },
    ];
  }

  private _generateManifestIcons(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): Array<I_ManifestIcon> {
    const icons: Array<I_ManifestIcon> = [
      {
        src: !brand?.favicon_preview_url ? `${DEFAULT_FAVICON_PATH}favicon.ico` : this._generateIconUrl(brand, themeOverride),
        type: "image/x-icon",
        sizes: "16x16",
      },
    ];

    // If there is no custom favicon, then we dont need to generate the custom ones but just use the default
    if (!brand?.favicon_preview_url) {
      icons.push({
        src: `${DEFAULT_FAVICON_PATH}favicon_144x144.png`,
        type: "image/png",
        sizes: "144x144",
      } as I_ManifestIcon);

      return icons;
    }

    for (const size of ICON_SIZES) {
      icons.push({
        src: this._generateIconUrl(brand, themeOverride, size),
        type: "image/png",
        sizes: `${size}x${size}`,
      });
    }
    return icons;
  }

  private _generateIconUrl(brand: BrandInfoEntry, themeOverride: T_BrandThemeOverride | undefined, size?: string): string {
    const brandId = themeOverride?.favicon_preview_url ? themeOverride.owned_brand_id : brand.id;
    const practiceId = this._jwtService.getJWT()?.practice_id;
    if (size) {
      if (!ICON_SIZES.includes(size)) throw new Error(`Invalid icon size: ${size}`);
      return `${protocol}//${host}/cdn/assets/original/favicon/favicon_${size}x${size}_${practiceId}_${brandId}.png`;
    }

    return `${protocol}//${host}/cdn/assets/original/favicon/favicon_${practiceId}_${brandId}.ico`;
  }

  private _generateIconsMarkup(brand: BrandInfoEntry, themeOverride: T_BrandThemeOverride | undefined): string {
    let html = `<link rel="icon" type="image/x-icon" sizes="16x16" href="${this._generateIconUrl(brand, themeOverride)}">`;
    for (const size of ICON_SIZES) {
      html += `<link rel="icon" type="image/png" sizes="${size}x${size}" href="${this._generateIconUrl(brand, themeOverride, size)}">`;
    }
    return html;
  }
}
