import { Injectable } from "@angular/core";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { Observable, from, map, of, switchMap, tap } from "rxjs";
import { BrowserCrypto } from "@shared/browser-crypto";
import { CacheService } from "./cache.service";
import { JWTService } from "./jwt.service";
import { FeatureFlagsService } from "./feature-flags.service";
import { E_PatientFeatures } from "@backend/common/enums/feature-flags.enum";

type T_CachedHttpQueryOptions = {
  ttl?: number;
  /**
   * Whether or not to cache the result using the patient's ID, SID and access level. This is useful for caching queries that are not patient-specific such as the common query but
   * still allowing the queries which are patient-specific to be cached against the patient (e.g. bookable appointments where new vs existing patient is important)
   */
  notPatientSpecific?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class CachedHttpService implements Pick<HttpService, "query"> {
  constructor(
    private _httpService: HttpService,
    private _cacheService: CacheService,
    private _jwtService: JWTService,
    private _featureFlagsService: FeatureFlagsService
  ) {}

  /**
   * Caches the result of a GraphQL query in session storage so that it can be re-used if the patient or application reloads the page
   *
   * WARNING Do not use this if the GraphQL query will return any patient PII
   *
   * @param operationName Name of the GraphQL operation
   * @param query GraphQL query
   * @param options Options
   *
   * @returns Observable
   */
  public query<T>(operationName: GRAPHQL_OPERATION_NAMES, query: string, options: T_CachedHttpQueryOptions = {}): Observable<T | any> {
    const { ttl = 300 } = options; // Default the cache TTL to 5 minutes
    const queryObservable = () => this._httpService.query(operationName, query, options); // Define this here to avoid duplicate code

    if (!this._featureFlagsService.getFeatureFlagValue(E_PatientFeatures.SQL_QUERY_REDUCTION, false)) {
      return queryObservable();
    }

    return this._getHashedCacheKey(options, operationName, query).pipe(
      switchMap((cacheKey) => {
        if (!cacheKey) {
          // The browser doesn't support SHA-256 hashing, so we can't cache the result
          return queryObservable();
        }

        const cachedResult = this._cacheService.getJsonSession<any>(cacheKey);

        if (cachedResult) {
          // There's a valid cached result, so return it
          return of(cachedResult);
        }

        // There's no cached result, so query the server and cache the result
        return queryObservable().pipe(
          tap((result) => {
            this._cacheService.setJsonSession(cacheKey, result, Date.now() + ttl * 1000);
          })
        );
      })
    );
  }

  private _getHashedCacheKey(options: T_CachedHttpQueryOptions, operationName: GRAPHQL_OPERATION_NAMES, query: string): Observable<string | null> {
    const { notPatientSpecific } = options;
    let key = query;

    if (!notPatientSpecific) {
      key = `${key}.${this._jwtService.getJWT("sid")}.${this._jwtService.getJWT("access_level")}.${this._jwtService.getJWT("patient_id") || "public"}`;
    }

    return from(BrowserCrypto.sha256(key)).pipe(
      map((hash) => {
        if (!hash) {
          return null;
        }

        return `graphql-cache:${operationName}:${hash}`;
      })
    );
  }
}
