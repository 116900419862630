import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { BehaviorSubject, Observable, catchError, map, of, tap, filter } from "rxjs";
import { LocationService } from "./location.service";

export enum E_StripePaymentMethod {
  card = "card",
  applePay = "apple_pay",
  googlePay = "google_pay",
}

export enum E_PaymentState {
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR",
  TAKING_PAYMENT = "TAKING_PAYMENT",
  SUCCESS = "SUCCESS",
}

interface I_PaymentState {
  state: E_PaymentState;
  data?: any;
  paymentMethod?: string;
}

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  public formComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public paymentState = new BehaviorSubject<I_PaymentState | null>(null);

  constructor(private _http: HttpService, private _locationService: LocationService) {}

  public createStripeCheckoutSession(
    amountOrSiteAppointmentTypes: number | Array<{ id: string; practitioner_id: string }>,
    appointmentIds?: Array<string>,
    isNewPatient?: boolean
  ): Observable<string> {
    const newItem = this._getNewItem(amountOrSiteAppointmentTypes, true);
    return this._http
      .mutation(
        "createStripeCheckoutSession",
        `{
      createStripeCheckoutSession(new_item: {
        ${newItem}
        ${appointmentIds?.length ? `appointment_ids: [${appointmentIds.map((id) => `"${id}"`).join(", ")}]` : ""}
        ${isNewPatient === undefined ? "" : `isNewPatient: ${isNewPatient}`}
      }) {
        url
      }
    }`
      )
      .pipe(
        catchError(() => {
          this._handlePaymentError();

          return of(null);
        }),
        filter((result) => !!result),
        map((result: any) => {
          const data = result.data?.createStripeCheckoutSession;

          if (!data) {
            this._handlePaymentError();
            return;
          }

          return data.url;
        }),
        tap((url) => {
          window.open(url, "_self");
        })
      );
  }

  private _getNewItem(amountOrSiteAppointmentTypes: number | Array<{ id: string; practitioner_id: string }>, includeOrigin = false): string {
    let newItem = `hostname: "${this._locationService.hostname}"`;

    if (includeOrigin) {
      newItem = `${newItem},
        origin: "${this._locationService.actualHostname}"`;
    }

    if (typeof amountOrSiteAppointmentTypes === "number") {
      const amount: number = amountOrSiteAppointmentTypes;

      newItem = `${newItem}
        amount: ${amount}`;
    } else {
      newItem = `${newItem}
        site_appointment_types: [${amountOrSiteAppointmentTypes
          .map(
            (siteAppointmentType) => `{
          id: "${siteAppointmentType.id}",
          practitioner_id: "${siteAppointmentType.practitioner_id}"
        }`
          )
          .join(", ")}]`;
    }

    return newItem;
  }

  private _handlePaymentError(): void {
    this.paymentState.next({
      state: E_PaymentState.ERROR,
      data: {
        message: "Something went wrong. Please try again later.",
      },
    });
  }
}
