import { Injectable } from "@angular/core";

export interface I_PreferencesStrings {
  unsubscribing: {
    heading: string;
    paragraph: string;
  };
  subscribing: {
    heading: string;
    paragraph: string;
  };
  unsubscribed: {
    heading: string;
    subheading: string;
    paragraph: string;
    button: string;
  };
  subscribed: {
    heading: string;
    subheading: string;
    paragraph: string;
    button: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class LocalisationService {
  private _countryCode: string;

  /*
    Dentally/db    |     UK         |     AU         |      NZ        | Can
    address_line_1 | Address Line 1 | Address Line 1 | Address Line 1 | Address Line 1
    address_line_2 | Address Line 2 | Address Line 2 | Address Line 2 | Address Line 2
    address_line_3 | Address Line 3 | Suburb         | Suburb         | Address Line 3
    town           | Town           | City           | City           | City
    county         | County         | State          | Region         | Province
    postcode       | Post Code      | Post Code      | Post Code      | Post Code
  */

  public set country_code(code: string) {
    this._countryCode = code;
  }

  public get country_code(): string {
    return this._countryCode;
  }

  public get addressLine3Label(): string {
    switch (this._countryCode) {
      case "AU":
      case "NZ":
        return "Suburb";

      default:
        return "Address line 3";
    }
  }

  public get townLabel(): string {
    switch (this._countryCode) {
      case "AU":
      case "NZ":
      case "CAN":
        return "City";

      default:
        return "Town";
    }
  }

  public get countyLabel(): string {
    switch (this._countryCode) {
      case "AU":
        return "State";
      case "NZ":
        return "Region";
      case "CAN":
        return "Province";

      default:
        return "County";
    }
  }

  public get preferences(): I_PreferencesStrings {
    const countryCode = this._countryCode;
    const processingParagraph = "Please wait while we process your request.";
    const strings: I_PreferencesStrings = {
      unsubscribing: {
        heading: "Unsubscribing",
        paragraph: processingParagraph,
      },
      subscribing: {
        heading: "Subscribing",
        paragraph: processingParagraph,
      },
      unsubscribed: {
        heading: "You have successfully unsubscribed from our marketing emails.",
        subheading: "We're sorry to see you go",
        paragraph: "",
        button: "Subscribe",
      },
      subscribed: {
        heading: "You have successfully subscribed to our marketing emails.",
        subheading: "Thank you for subscribing",
        paragraph: "",
        button: "Unsubscribe",
      },
    };

    if (countryCode === "GB") {
      strings.unsubscribed.paragraph = "We'll continue sending you appointment reminders and important information about your dental treatment.";
      strings.subscribed.paragraph =
        "We'll send you marketing messages along with appointment reminders and important information about your dental treatment.";
    } else {
      strings.unsubscribed.paragraph = "We'll continue sending you important information about your dental treatment.";
      strings.subscribed.paragraph = "We'll send you marketing messages along with important information about your dental treatment.";
    }

    return strings;
  }
}
