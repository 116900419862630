import { RESERVED_SUBDOMAINS } from "@shared/constants";
import { isPrStage } from "@shared/utils";

/**
 * The placeholder values are replaced by:
 * '_getReplaceValues' when deploying through SST
 * 'dev/devenv.ts' when running locally
 */
export const environment = {
  ACCOUNT: "#{ACCOUNT}#",
  get APP_PATIENT_URL() {
    return `https://${this.STAGE}-patient.${this.HOST_ZONE}/`;
  },
  BACKEND_DOMAIN: "#{BACKEND_DOMAIN}#",
  get CUSTOM_DOMAINS() {
    const customDomains = "#{CUSTOM_DOMAINS}#";

    if (customDomains.startsWith("[")) return JSON.parse(customDomains);

    return [];
  },
  ENABLE_SERVICE_WORKER: "#{ENABLE_SERVICE_WORKER}#",
  GA_TRACKING_ID_PATIENT_APP: "#{GA_TRACKING_ID_PATIENT_APP}#",
  get GLOBAL_URL() {
    if (this.STAGE === "e2e" || isPrStage(this.STAGE)) {
      return `https://global-${this.STAGE}-apis.${this.BACKEND_DOMAIN}`;
    }

    return `https://apis.${this.BACKEND_DOMAIN}`;
  },
  get GRAPH_URL() {
    return `https://${this.STAGE}-graph.${this.BACKEND_DOMAIN}/`;
  },
  HOST_ZONE: "#{HOST_ZONE}#",
  get IS_GLOBAL_DOMAIN() {
    return new RegExp(`(${RESERVED_SUBDOMAINS.join("|")})\.(dentr|(sandbox\.)?portal\.dental)`).test(location.hostname);
  },
  get IS_PROD() {
    return ["true"].includes("#{IS_PROD}#"); // To prevent "This comparison appears to be unintentional because the types '"#{IS_PROD}#"' and '"true"' have no overlap" error
  },
  OPTIMIZELY_KEY: "#{OPTIMIZELY_KEY}#",
  PATIENT_APP_PUSHER_CLUSTER: "#{PATIENT_APP_PUSHER_CLUSTER}#",
  PATIENT_APP_PUSHER_EVENT: "#{PATIENT_APP_PUSHER_EVENT}#",
  PATIENT_APP_PUSHER_KEY: "#{PATIENT_APP_PUSHER_KEY}#",
  PUSHER_CLUSTER: "#{PUSHER_CLUSTER}#",
  PUSHER_EVENT: "#{PUSHER_EVENT}#",
  PUSHER_KEY: "#{PUSHER_KEY}#",
  PWA_INSTALLABLE: "#{PWA_INSTALLABLE}#",
  get REST_LEGACY_URL() {
    return `https://${this.STAGE}-apis.${this.BACKEND_DOMAIN}`;
  },
  get REST_URL() {
    return `https://${this.STAGE}-rest.${this.BACKEND_DOMAIN}`;
  },
  get STAGE() {
    return window.STAGE || "#{STAGE}#";
  },
  GOOGLE_RECAPTCHA_SITE_KEY: "#{GOOGLE_RECAPTCHA_SITE_KEY}#",
  VERSION: "#{VERSION}#",
  clearCache: true,
  hmr: false,
  production: true,
  releaseStage: "dev",
};
